@import '../../../css/variables';
@import '../../../css/mixins';

.search_box {

  @include small-desktop {
    max-width: 850px;
  }

  @include desktop-large {
    max-width: 1100px;
  }

  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 auto;

  .search_icon, .clear_icon {
    width: 32px;
    height: 32px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $grey_dark;
    }
  }

  .search_input_wrapper {
    width: 100%;
    display: flex;
    .input {
      width: 100%;
      margin-left: 16px;
      letter-spacing: 0.02em;
      background-color: transparent;

      border: none;
      box-shadow: none;
      outline: none;

      &::placeholder {
        font-weight: normal;
      }

      &::-ms-clear {
        display: none;
      }
    }
  }

  animation-name: fadeInLeft;
  animation-duration: 500ms;
  animation-fill-mode: both;

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
