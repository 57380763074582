@import '../../css/mixins';
@import '../../css/variables';

$tile_head_height: 52px;
$tile_footer_height: 52px;

.tile {
  box-sizing: border-box;
  background-color: $white_white;
  border: 1px solid $bluegrey_borders;
  @include border-radius(4px);

  margin-bottom: 12px;
  position: relative;

  &:hover {
    .actions {
      display: block;
    }
  }

  @include phone-small {
    width: 100%;
  }

  @include phone {
    flex: 0 1 auto;
    width: 49%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  @include tablet {
    flex: 0 1 auto;
    width: 32.3%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  @include desktop {
    flex: 0 1 auto;
    width: 24%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  @include desktop-retina-large {
    flex: 0 1 auto;
    width: 19%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
      box-sizing: border-box;
      min-height: $tile_head_height;
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .cover {
      background-size: cover;
      height: 100%;
      position: relative;
      .duration {
        position: absolute;
        padding: 2px 4px;
        bottom: 4px;
        right: 4px;
        line-height: 13px;
        background-color: $black_black;
        color: $grey_primary;
      }
      .known_words_progress {
        position: absolute;
        width: 100%;
        top: 0;
      }
    }
    .footer {
      box-sizing: border-box;
      min-height: $tile_footer_height;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        display: flex;
        align-items: center;
        overflow: hidden;

        .avatar {
          margin-right: 10px;
        }

        .producer {
          min-width: 0px;
        }
      }
    }
  }

  .actions {
    position: absolute;
    right: 4px;
    top: $tile_head_height + 4px;
    z-index: 1;
    display: none;
    &.isMobile {
      display: block;
    }
  }
}

.paidContent {
  position: absolute;
  border-radius: 0 8px 8px 0;

  display: inline-block;
  line-height: 1;

  background-color: $light_darkline;
  color: rgba(0,0,0,.6);

  padding: 4px 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(35deg);

  @include shadow-minimalistic;

  &.paid {
    background-color: $lightgreen;
    color: rgba(255,255,255,.8);
  }

  &:before {
    position: absolute;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
    top: 50%;
    right: 100%;
    content: '';
    background-color: inherit;
    width: 28px;
    height: 28px;
  }

  &:after {
    content: '';
    top: 50%;
    background-color: $white_white;
    box-shadow: 0 -1px 1px 0 rgba(0,0,0,.3);
    border-radius: 500rem;
    box-sizing: inherit;

    height:10px;
    left:-5px;
    margin-top:-5px;
    position:absolute;
    width:10px;
  }
}
