@import '../../../css/mixins';

.root {
  .paper {
    @include border-radius(8px);
    width: 100%;
  }
  .fullWidth {
    margin: 0px;
  }
}
