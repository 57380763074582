$head_height: 65px;

.popover {
  .paper {
    height: 315px;
    width: 360px;
    .content {
      height: calc(100% - #{$head_height});
      overflow: auto;
      .progress {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .item {
    .caption {
      margin-top: 6px;
    }

    .text {
      flex: 1;
    }

    .trackable {
      .mediaitem_publish {
        display: flex;
        font-size: 10px;
        align-items: center;
        margin-top: 12px;
        .avatar {
          margin-right: 10px;
        }
      }
    }
  }
}

.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .symbol {
      box-sizing: border-box;

      width: 160px;
      height: 160px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 18px;

      .icon {
        transform: rotate(15deg);
      }
    }
  }
}
