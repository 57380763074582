.group {
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  padding-bottom: 16px;

  .mediaTags {
    position: absolute;
    top: 0;
  }

  .loader {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .tile {
    position: relative;
    .actions {
      position: absolute;
      right: 4px;
      top: 4px;
      z-index: 1;
    }
    img {
      width: 100%;
      display: block;
    }
  }
}
