@import '~normalize.css';

@import '../css/variables';
@import '../css/mixins';
@import '../css/text.module';

html, body, :global(#root) {
  height: 100vh;
  height: -webkit-fill-available;
}

body {
  @extend .roboto_regular;
}

.entry {
  height: 100%;
  height: -webkit-fill-available;
  flex-direction: column;
  display: flex;
  position: relative;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    transition: margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: 0;

    &.moveToRight {
      transition: margin-left 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      margin-left: $menuWidth;
    }
  }
}
