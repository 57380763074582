@import '../../css/mixins';
@import '../../css/variables';

.banner {
  max-height: 352px;

  background-color: #4B8CC8;
  background-position: center;
  background-size: cover;

  & > div {
    & > svg {
      max-height: 300px;
    }
  }

  .header {
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    color: $white_white;
    min-height: 52px;
    .logo_wrapper {
      margin-left: 15px;
      margin-top: 4px;
      h1 {
        display: inline-block;
        white-space: nowrap;
        position: relative;
        top: -1px;
        color: white;
        font-size: 14px;
      }
      .logo {
        display: inline-block;
        @include size-icon(52px, 34px);
      }
      .text {
        display: inline-block;
        white-space: nowrap;
        position: relative;
        top: -1px;
      }
    }
  }
}
