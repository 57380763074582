@import "../../../../../css/text.module";

.info {
  padding: 20px 36px;
  max-width: 1200px;

  .aboutPlaceholder {
    @extend .capitalize;
  }
}
