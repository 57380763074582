.produced_content {
  margin-bottom: 15px;

  .header {
    margin: 25px 20px;
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .seeMore {
    text-align: center;
  }
}
