.tile {
  position: relative;

  &:hover {
    .actions {
      .share_action {
        display: inline-flex;
      }
    }

    .coverWrapper {
      .playOverlayWrapper {
        display: block;
      }
    }
  }

  .coverWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .playOverlayWrapper {
      display: none;

      & > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
      }

      .playOverlay {
        background-color: black;
        opacity: 0.5;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
      }
    }
  }

  .actions {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1;
    display: flex;
    align-items: center;
    & > div {
      margin-left: 5px;
    }
    &.isMobile {
      display: flex;
      .share_action {
        display: inline-flex;
      }
    }
    .share_action {
      display: none;
    }
  }
}

.mediaItemMetaInfo {
  display: flex;
  & > div {
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
