.continueWatching {
  margin: 15px 10px 35px 10px;
  .header {
    display: flex;
    align-items: center;
  }
  .empty {
    text-align: center;
    margin: 0px 10px;
  }
}
