.order {
  .anchor {
    display: flex;
    align-items: center;
    cursor: pointer;
    .title {
      margin-left: 10px;
    }
  }
  .list {
    min-width: 224px;
  }
}
