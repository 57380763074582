@import "../../../css/variables";

.item {
  border-bottom: $light_background 4px solid;
  &:last-child {
    border-bottom: none;
  }

  .created_at {
    margin-top: 15px;
  }

  .admin_avatar {
    background-color: $red;
  }
}
