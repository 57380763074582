.user_block {
  display: flex;
  align-items: center;
  cursor: pointer;

  .avatar {
    display: block;
  }

  .expander {
    cursor: pointer;
  }

  .menu_divider {
    margin: 8px 0px;
  }
}
