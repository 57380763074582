@import '../../css/variables';

.loading_overlay {
  opacity: 0;
  position: absolute;
  background-color: $white_white;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1010;
  animation-name: fadeIn;
  animation-delay: 500ms;
  animation-duration: 500ms;
  animation-fill-mode: both;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.5;
    }
  }
}
