.main {
  .body {
    margin-top: 25px;
    font-size: 1.3rem;
    line-height: 1.6;
  }
}
.title {
  overflow: hidden;
  max-height: 168px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}

.description {
  overflow: hidden;
  max-height: 160px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}
