@import '../../../../css/variables';

.notifications {
  .block {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px;
    }
    .checkbox {
      display: block;
    }

    .toggle_buttons {
      .selected {
        background-color: $green;
        color: $white_white;
        &:hover {
          background-color: $darkgreen;
        }
      }
    }
  }
}
