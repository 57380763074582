@import '../../../../css/mixins';

.folder {
  height: 80px;
  @include border-radius(10px);

  padding: 16px 12px;
  box-sizing: border-box;
  cursor: pointer;

  position: relative;

  &:hover {
    @include shadow-minimalistic;
    .wrapper {
      .icon {
        width: 0px;
        margin-right: 0px;
        &.icon_with_menu {
          width: 48px;
          margin-right: 12px;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      margin-right: 12px;
      overflow: hidden;
      width: 48px;
      &.icon_with_menu {
        .svg {
          @include size-icon(48px, 48px);
        }
        margin-right: 0px;
        width: 0px;
      }
    }

    .legend {
      overflow: hidden;
      flex: 1;
      line-height: 1.5;
    }

    .avatar {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}
