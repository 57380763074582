@import '../../../../css/mixins';

.socialAccounts {
  max-width: 640px;
}

.input {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
}
