@import '../../css/mixins';

.landing {
  position: relative;

  .closeButton {
    position: absolute;
    right: 0;
    right: 0;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1024px;
    margin: auto;
    .subscriptionButton {
      flex: 1;
      margin: 15px 0px;
    }
    @include tablet-landscape-max {
      img {
        width: 100%;
      }
    }
  }
  .header {
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      text-align: center;
      margin: 0px 20px;
      padding: 10px;
    }
  }
}
