@import './variables';

.roboto_regular {
  font-family: 'Roboto', sans-serif;
  font-weight: initial;
}
.roboto_medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.roboto_bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.lato_bold {
  font-family: 'Lato';
  font-weight: 700;
}
.lato_black {
  font-family: 'Lato';
  font-weight: 900;
}

.hero {
  font-size: 72px;
}
.h1 {
  font-size: 60px;
}
.h2 {
  font-size: 48px;
}
.h3 {
  font-size: 36px;
}
.h4 {
  font-size: 24px;
}
.h5 {
  font-size: 21px;
}
.subheading {
  font-size: 18px;
}
.paragraph_2 {
  font-size: 16px;
}
.paragraph_1 {
  font-size: 14px;
}
.large_small {
  font-size: 13px;
}
.small {
  font-size: 12px;
}
.extra_small {
  font-size: 11px;
}
.micro {
  font-size: 10px;
}
.extra_micro {
  font-size: 8px;
}

.cl_black_dark {
  color: $black_dark;
}
.cl_grey_footer {
  color: $grey_footer;
}
.cl_grey_medium {
  color: $grey_medium;
}
.cl_bluegrey_icons {
  color: $bluegrey_icons;
}
.cl_bluegrey_dark {
  color: $bluegrey_dark;
}
.cl_blue_primary {
  color: $blue_primary;
}
.cl_bluegrey_cards {
  color: $bluegrey_cards;
}
.cl_white_white {
  color: $white_white;
}
.cl_grey_dark {
  color: $grey_dark;
}
.cl_bluegrey_medium {
  color: $bluegrey_medium;
}
.cl_black_black {
  color: $black_black;
}
.cl_red {
  color: $red;
}
.cl_grey_light {
  color: $grey_light;
}
.cl_grey_primary {
  color: $grey_primary;
}

.dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}
