.carousel {
  overflow: visible;

  &:hover {
    & > ul {
      z-index: 1;
    }
  }

  & > ul {
    & > li {
      & > div {
        margin: 0px 10px;
      }
    }
  }

  :global(.react-multiple-carousel__arrow) {
    height: 100%;
  }

  :global(.react-multiple-carousel__arrow--right) {
    right: 8px;
    border-radius: 0px 4px 4px 0px;
  }

  :global(.react-multiple-carousel__arrow--left) {
    left: 8px;
    border-radius: 4px 0px 0px 4px;
  }

  :global(.react-multi-carousel-item) {
    transform-style: initial;
    &:hover {
      z-index: 1;
    }
  }
}
