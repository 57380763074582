.facebook, .instagram {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;

  & + .title {
    display: none;
  }
}

.facebook {
  background-image: url(../../../ui/svg/icons/social-accounts/facebook.svg);
}

.instagram {
  background-image: url(../../../ui/svg/icons/social-accounts/instagram.svg);
}

.howItWorks {
  video {
    width: 100%;
  }
}

.blog_links {
  margin-bottom: 15px;
}
