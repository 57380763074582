@import '../../../../../css/variables';

.avatar {
  width: 224px;
  height: 224px;

  position: relative;

  &.processing {
    :global(.filepond--root) {
      opacity: 1;
    }
  }

  .upload_photo_button, .remove_photo_button {
    position: absolute;
    bottom: 0;
    right: 0;
    &.hidden {
      display: none;
    }
  }

  .upload_photo_button {
    right: 0;
  }

  .remove_photo_button {
    left: 0;
  }

  :global(.filepond--root) {
    height: 224px;
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    :global(.filepond--panel-root) {
      background-color: transparent;
    }

    :global(.filepond--item) {
      min-height: 100%;

      &[data-filepond-item-state='load-invalid'], &[data-filepond-item-state='processing-error'] {
        background-color: $red;
        opacity: 0.9 !important;
      }

      :global(.filepond--file) {
        flex-flow: column;

        :global(.filepond--file-status) {
          :global(.filepond--file-status-sub) {
            opacity: 1;
          }
        }

        :global(.filepond--file-status *) {
          white-space: normal;
        }

        :global(.filepond--file-info) {
          width: 100%;
          :global(.filepond--file-info-main) {
            max-width: 85%;
          }
        }
      }
    }
  }
}
