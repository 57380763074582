@import './mixins';
@import './variables';

.circle {
  @include border-radius(50%);
}

.capsule {
  @include border-radius(9999px);
}

.hidden {
  display: none !important;
}

.bg_light_background {
  background-color: $light_background;
}

.bg_light_lightline {
  background-color: $light_lightline;
}

.bg_white_white {
  background-color: $white_white;
}
