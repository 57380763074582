.media_items {
  .header {
    margin: 25px 20px;
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}

.loader {
  text-align: center;
  margin: 20px 0px 30px 0px;
}
