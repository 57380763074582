@import '../../css/variables';
@import '../../css/mixins';

.header {
  height: $head_height;
  min-height: $head_height;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;

  position: relative;

  &.hidden {
    display: none;
  }

  .left, .center, .right {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .left {
    .menuIcon {
      margin-right: 8px;
    }
    .menuButton {
      margin-left: 12px;
      &.icon {
        margin-left: 0px;
        margin-right: 0px;
      }
      .text {
        text-transform: none;
      }
    }
  }

  .center {
    justify-content: center;
  }

  @include phone-max {
    .logo {
      display: none;
    }
  }

  .logo {
    img {
      display: block;
    }
  }

  .right {
    justify-content: flex-end;
    .block {
      &:last-child {
        margin-right: 12px;
      }
      .loginButton {
        text-transform: none;
      }
      &.language_block {
        margin: 0px 10px;
      }
    }
  }

  .search_box {
    background-color: $grey_footer;
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 2;
  }
}
