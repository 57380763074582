@import "../../css/variables";

.comments {
  .header {
    margin: 20px 0px;
  }
  .list {
    background-color: $white_white;
    margin: 16px 0px;
  }

  .warning_block {
    padding: 17px 24px 24px 24px;
    background-color: $yellow;
    margin-bottom: 20px;
  }
}
