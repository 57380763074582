@import '../../css/variables';
@import '../../css/mixins';

.statisticContainer {
  display: flex;
  width: 100%;
  min-height: 700px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sunBurstBackdrop {
  width: 600px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart {
  @include tablet-max {
    width: 375px;
    height: 375px;
  }

  @include phone-max {
    width: 300px;
    height: 300px;
  }

  align-items: center;
  display: flex;

  width: 600px;
  height: 600px;

  &.hidden {
    display: none;
  }

  .sunburst {
    width: 100%;
    height: 600px;
  }

  .empty {
    width: 93%;
  }
}

.filter {
  display: flex;
  width: 90%;
  flex-direction: column;
}

.filterTitle {
  text-align: center;
}

.filterControls {
  display: flex;
  margin-bottom: 25px;
}

.filterInputsWrapper {
  display: flex;
  justify-content: space-between;
}

.bigdefContainer {
  overflow: hidden;
}

.wtypes {
  .clickableCell {
    cursor: pointer;
    color: $blue_primary;
  }
  .currentNode {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.social_buttons {
  padding: 20px 20px 0px 20px;
  display: flex;

  .button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
