@mixin phone-small {
  @media only screen and (min-width: 320px) {
    @content;
  }
}

@mixin phone-small-max {
  @media only screen and (max-width: 319px) {
    @content;
  }
}

@mixin phone {
  @media only screen and (min-width: 375px) {
    @content;
  }
}

@mixin phone-max {
  @media only screen and (max-width: 374px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 640px) {
    @content;
  }
}

@mixin tablet-max {
  @media only screen and (max-width: 639px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media only screen and (min-width: 940px) {
    @content;
  }
}

@mixin tablet-landscape-max {
  @media only screen and (max-width: 939px) {
    @content;
  }
}

@mixin small-desktop {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin small-desktop-max {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-max {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop-large {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}

@mixin desktop-large-max {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}

@mixin desktop-retina {
  @media only screen and (min-width: 1800px) {
    @content;
  }
}

@mixin desktop-retina-large {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}

@mixin desktop-retina-max {
  @media only screen and (max-width: 2559px) {
    @content;
  }
}

@mixin desktop-max {
  @media only screen and (min-width: 2560px) {
    @content;
  }
}

@mixin fill_color_icon($color) {
  svg {
    :global(.fill_area), &:global(.fill_area) {
      fill: $color;
    }
  }
}

@mixin size-icon($width, $height) {
  width: $width;
  height: $height;
  svg {
    width: $width;
    height: $height;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin shadow-minimalistic {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(184, 192, 201, 0.4);
}
