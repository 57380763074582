.tile {
  &.hovered {
    transform: scale(1.12);
    transition: all .35s ease-in-out;
    z-index: 1;
  }

  .mediaTags {
    position: absolute;
    top: 0
  }
}

.hoverTileWrapper {
  position: absolute;
  background-color: inherit;
  width: 100%;
}

.themeHeader {
  display: flex;
  align-items: center;
}

.loader {
  text-align: center;
  margin: 20px 0px 30px 0px;
}
