@import '../css/mixins';
@import '../css/variables';

.mediaItems {
  height: 100%;

  .filter_order, .tiles {
    @include small-desktop-max {
      margin: 0 auto;
    }
  }

  .filter_order {
    height: 80px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5%;
    padding-right: 1.5%;
    box-sizing: border-box;

    .level_filter {
      display: flex;
      align-items: center;
    }

    & > div {
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .tiles {
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    .loader {
      font-style: italic;
      color: $bluegrey_text;
    }
  }
}

.loadingIndicatorWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100px;
}
