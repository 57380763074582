.feedback_creator {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  &.hidden {
    display: none;
  }
}
