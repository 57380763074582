.field_filter {
  .anchor {
    display: flex;
    align-items: center;
    cursor: pointer;
    .title {
      margin-right: 10px;
    }
  }
  .list {
    min-width: 224px;
    .list_item {
      padding: 0px;
      .label {
        width: 100%;
        margin: 0px;
        padding: 8px 4px;
      }
    }
  }
}
