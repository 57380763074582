.large {
  width: 224px;
  height: 224px;
}
.medium {
  width: 96px;
  height: 96px;
}
.small {
  width: 36px;
  height: 36px;
}
