@import '../../../css/variables';
@import '../../../css/mixins';

.withTheme {
  margin: 15px 10px 35px 10px;

  .groups {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}

.group {
  margin-bottom: 12px;

  @include phone-small {
    width: 100%;
  }

  @include phone {
    flex: 0 1 auto;
    width: 49%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  @include tablet {
    flex: 0 1 auto;
    width: 32.3%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  @include desktop {
    flex: 0 1 auto;
    width: 24%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  @include desktop-retina-large {
    flex: 0 1 auto;
    width: 19%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}

.loadingIndicatorWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100px;
  .loader {
    font-style: italic;
    color: $bluegrey_text;
  }
}
