//@import '../css/mixins';

.posts {
  height: 100%;

  margin: 25px 0px;

  .items {
    max-width: 1100px;
    margin: 0 auto;
  }

  .items {
    .has_more_loader {
      width: 100%;
      text-align: center;
    }
  }
}
