@import '../../css/mixins';

.empty {
  width: 25%;
  margin: 0 auto;
  padding: 2% 0px;
  min-width: 280px;

  .wrapper {
    position: relative;
    @include border-radius(50%);
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .folder {
        width: 50%;
        height: 50%;
        & > svg {
          width: 100%;
          height: 80%;
        }
      }

      .title {
        margin-top: 0px;
        margin-bottom: 15px;
        padding: 0px 20px;
        text-align: center;
      }
    }
  }
}
