$head_height: 51px;
$menuWidth: 240px;

$white_white: rgba(255, 255, 255, 1);

$black_black: rgba(0, 0, 0, 1);
$black_dark: rgba(33, 33, 33, 1);

$blue_primary: rgba(32, 119, 208, 1);
$blue_secondary: rgba(132, 173, 220, 1);
$blue_light: rgba(214, 227, 243, 1);

$bluegrey_borders: rgba(184, 192, 201, 0.4);

$bluegrey_dark: rgba(69, 74, 79, 1);
$bluegrey_medium: rgba(100, 110, 120, 1);
$bluegrey_cards: rgba(133, 138, 144, 1);
$bluegrey_icons: rgba(184, 192, 201, 1);
$bluegrey_text: #b3b8c1;

$grey_primary: rgba(184, 192, 201, 1);
$grey_dark: rgba(76, 76, 76, 1);
$grey_footer: rgba(50, 51, 53, 1);
$grey_medium: rgba(114, 114, 114, 1);
$grey_light: rgba(151, 151, 151, 1);

$light_background: rgba(245, 245, 245, 1);
$light_lightline: rgba(238, 238, 238, 1);
$light_darkline: rgba(211, 211, 211, 1);

$red: rgba(222, 44, 44, 1);
$red_light: rgba(248, 213, 213, 1);

$green: rgba(87, 184, 85, 1);
$darkgreen: rgb(33, 162, 69);
$lightgreen: rgba(128, 218, 109, 1);

$yellow: rgba(255, 185, 0, 1);
$orange: rgba(242, 129, 12, 1);

:export { grey_primary: $grey_primary; }
:export { white_white: $white_white; }
:export { black_dark: $black_dark; }
:export { blue_primary: $blue_primary; }
:export { red: $red; }
:export { yellow: $yellow; }
:export { green: $green; }
:export { orange: $orange; }
:export { blue_light: $blue_light; }
