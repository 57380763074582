@import '../../css/variables';
@import '../../css/mixins';

.content {
  height: calc(100vh - #{$head_height} - 48px - 40px);

  .words {
    flex: 1;
    padding-top: 10px;
    box-sizing: border-box
  }

  @include tablet-landscape-max {
    height: calc(100vh - #{$head_height} - 48px);
  }

  display: flex;
  flex-direction: column;

  .TotalInfoContainer {
    padding: 0px 15px;
    box-sizing: border-box;
  }
}
