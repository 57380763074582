@import '../css/mixins';

.profile {
  padding: 20px 20px;
  box-sizing: border-box;

  @include tablet-landscape-max {
    padding: 0px 5px;
  }

  .wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin: 0px auto;
    width: 100%;

    .content {
      @include shadow-minimalistic;
      @include border-radius(4px);

      width: 100%;
    }

    @include desktop-large {
      width: 1400px;
    }
  }
}
