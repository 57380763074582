@import '../../css/variables';
@import '../../css/mixins';

.drawer {
  width: $menuWidth;

  .drawerPaper {
    box-sizing: border-box;
    top: $head_height;
    height: calc(100% - #{$head_height});
    width: $menuWidth;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px, rgba(0, 0, 0, 0.3) 0 3px 7px;
  }

  .footer {
    padding: 10px 16px;
    .socials {
      & > a {
        margin-right: 10px;
      }
      display: flex;
      align-items: center;
      padding-bottom: 20px;
    }
    .links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 15px 0px;

      & > div {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .version {
      margin-top: 15px;
    }
  }
}

.menuItemText {
  text-transform: uppercase;
}

.subscription {
  .avatar {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    &:hover {
      transform: scale(1.5);
      border: 1px solid $bluegrey_borders;
    }
  }
}
