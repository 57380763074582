@import "../css/variables";
@import "../css/mixins";

.wrapper {
  width: 100%;
  min-width: 320px;
  height: 100%;

  .grid {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    max-width: 900px;
    margin: 0 auto;
    min-height: 100%;
    padding: 0px 24px 24px 24px;

    &.thank_you {
      padding-left: 0px;
      padding-right: 0px;
    }

    h1 {
      font-size: 40px;
      font-weight: 900;
      //color: #212121;
      text-align: center;
      padding-top: 78px;
      padding-bottom: 40px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      line-height: 1.167;
      letter-spacing: -0.01562em;
      margin: 0;
    }

    @include tablet-max {
      padding: 0px 8px 24px 8px;
    }

    .decimalList {
      list-style-type: decimal;
    }

    .lowerAlphaList {
      list-style-type: lower-alpha;
    }

    .header {
      font-size: 40px;
      font-weight: 900;
      //color: $black_dark;
      text-align: center;
      padding-top: 78px;
      padding-bottom: 40px;
    }

    .source {
      text-align: center;
      video, img {
        max-width: 100%;
        margin: 10px 0;
      }

      img {
        max-width: 75%;
      }
    }

    @include tablet-max {
      .source {
        img {
          max-width: 100%;
        }
      }
    }

    .terms {
      .head {
        text-align: center;
        font-size: 24px;
        padding-top: 32px;
        padding-bottom: 16px;
      }

      .article {
        display: flex;
        padding: 18px 0;
      }

      .title {
        font-weight: bold;
        min-width: 20%;
        width: 20px;
      }

      .content {
        margin-left: 16px;

        div {
          margin: 6px 0;
          &:first-child, &:last-child {
            margin: 0;
          }
        }
      }
    }

    .about {
      .content {
        font-size: 14px;
        font-weight: 500;
        //color: $black_dark;
        text-align: left;
        line-height: 24px;
        padding-bottom: 40px;

        .sign {
          text-align: right;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }

    .thank_you {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
