.search_panel {
  max-width: 1100px;
  margin: 0 auto;

  height: 80px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5%;
  padding-right: 1.5%;
  box-sizing: border-box;

  .filters {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
