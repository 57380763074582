@import './variables';
@import './mixins';

.icon {
  @include size-icon(24px, 24px);
  display: block;

  &.hover {
    &:hover {
      cursor: pointer;
    }
  }

  &.fill_white_white {
    @include fill_color_icon($white_white);
  }

  &.fill_blue_primary {
    @include fill_color_icon($blue_primary);
  }

  &.fill_black_dark {
    @include fill_color_icon($black_dark);
  }

  &.fill_red {
    @include fill_color_icon($red);
  }

  &.fill_green {
    @include fill_color_icon($green);
  }

  &.fill_yellow {
    @include fill_color_icon($yellow);
  }

  &.fill_inherit {
    @include fill_color_icon(inherit);
  }
}
