@import '../../css/variables';

.person {
  padding: 25px 15px 25px 15px;
  border-bottom: 1px solid $bluegrey_borders;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }

  .wrapper {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;

    padding: 0px 45px;
    width: 100%;

    .description {
      margin-left: 25px;

      h3 {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 1.275rem;
        color: hsla(0, 0%, 0%, 1);
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        text-rendering: optimizeLegibility;
        font-size: 1.35824rem;
        line-height: 1.45;
      }

      .social_accounts {
        display: flex;
        a {
          margin-right: 5px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
