@import '../../css/variables';

.grid {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.text {
  font-size: 5vmin;
  font-weight: bold;
  padding-bottom: 1em;
  text-align: center;
}

.link {
  height: 34vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 2vmin;
  color: $blue_primary;
  text-decoration: none;
  cursor: pointer;
}
