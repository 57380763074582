@import "../../../css/variables";
@import "../../../css/mixins";

$app_bar_head_height: 64px;

.settings {
  .app_bar {
    position: relative;
  }

  .content {
    display: flex;
    height: 100%;
    overflow: auto;
    @include tablet-landscape-max {
      height: calc(100% - #{$app_bar_head_height});
    }

    .tabs {
      height: 100%;
      min-width: 200px;
      border-right: 1px solid $light_darkline;
      @include tablet-landscape-max {
        height: auto;
      }
    }

    .tab_content {
      width: 100%;
      overflow: auto;
      padding: 56px 37px;
      box-sizing: border-box;
      @include tablet-landscape-max {
        padding: 20px 10px;
      }
    }

    @include tablet-landscape-max {
      flex-direction: column;
    }
  }
}

.fieldLabel {
  text-transform: capitalize;
}
