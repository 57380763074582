@import '../../../css/variables';
@import '../../../css/mixins';

.badge {
  .root {
    color: $white_white;
  }

  .count {
    background-color: $green;
  }

  .icon {
    @include size-icon(35px, 35px);
  }
}
