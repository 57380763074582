@import '../../../css/mixins';

.folders {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  & > :global(.folder) {
    box-sizing: border-box;
    margin-bottom: 12px;

    @include phone-small {
      width: 100%;
    }

    @include phone {
      flex: 0 1 auto;
      width: 49%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }

    @include tablet {
      flex: 0 1 auto;
      width: 32.3%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }

    @include desktop {
      flex: 0 1 auto;
      width: 24%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }

    @include desktop-retina-large {
      flex: 0 1 auto;
      width: 19%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }
  }
}
