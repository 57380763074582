@import '../../../css/variables';

.grid {
  width: 100%;
  height: 100%;
  background-color: $light_lightline;
  background-image: url(../../../ui/svg/icons/header_logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ears {
  background-image: url(../../../ui/svg/icons/logo-not-found.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 16%;
  height: 40vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 3.3vmin;
  color: $orange;
  padding-bottom: 3.4vmin;
}

.alerts {
  font-size: 5vmin;
  font-weight: bold;
  color: $orange;
  padding-bottom: 1em;
}

.description {
  color: $grey_medium;
  font-size: 2.5vmin;
}
